<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules" :label-position="labelPosition" class="demo-form-inline search-form">
        <div class="search_form_item">
            <el-form-item prop="name" label="设备编号">
                <el-input v-model="searchInfo.deviceNo" @change="deviceChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name1" label="IMEI号码">
                <el-input v-model="searchInfo.imei" @change="imeiChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name2" label="运营商">
                <el-select v-model="searchInfo.deviceMode" clearable placeholder="请选择" @change="deviceModeChange">
                    <el-option v-for="item in deviceModeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name3" label="在线状态">
                <el-select v-model="searchInfo.status" clearable placeholder="请选择" @change="stateChange">
                    <el-option v-for="item in stateData" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name4" label="所属水司">
                <el-select v-model="searchInfo.enterpriseId" clearable filterable placeholder="请选择">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.id" @change="enterpriseIdChange">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name5" label="阀门状态">
                <el-select v-model="searchInfo.tapStatus" clearable placeholder="请选择">
                    <el-option v-for="item in tapStausData" :key="item.value" :label="item.label" :value="item.value" @change="tapStatusChange">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name6" label="电量">
                <el-input v-model="searchInfo.electricity" @change="electricityChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name7" label="批次编号">
                <el-input v-model="searchInfo.batchNo" @change="batchNoChange" clearable></el-input>
            </el-form-item>
            <el-form-item class="search-btn">
                <el-button type="primary" @click="searchList()">查询</el-button>
                <!-- <el-button type="primary" @click="addDevice()">注册新设备</el-button>
                <el-button type="primary" @click="batchAddDevice()">批量注册</el-button>
                <el-button type="primary" @click="batchUpdateDeviceNo()">批量设置设备编号</el-button> -->
            </el-form-item>
        </div>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            labelPosition: 'left',
            rules: {},
            deviceModeList: [{
                    value: '2',
                    label: '移动'
                },
                {
                    value: '3',
                    label: '电信'
                }
            ],
            stateData: [{
                    value: '0',
                    label: '在线'
                },
                {
                    value: '1',
                    label: '离线'
                }
            ],
            arrEnpr: [],
            tapStausData: [{
                    value: '0',
                    label: '关'
                },
                {
                    value: '1',
                    label: '开'
                },
                {
                    value: '2',
                    label: '未知'
                }
            ],
        }
    },
    computed: {},
    created() {},
    mounted() {
        this.getAllEnprs()
    },
    methods: {
        getAllEnprs() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        addDevice() {
            this.$router.push({
                path: '/',
                query: {
                    state: 'add'
                }
            })
        },
        batchAddDevice() {
            this.$router.push({
                path: '/',
                query: {
                    state: 'batch'
                }
            })
        },
        batchUpdateDeviceNo() {
            this.$router.push({
                path: '/',
                query: {
                    state: 'batchUpdate'
                }
            })
        },
        deviceChange(e) {
            this.searchInfo.deviceNo = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        imeiChange(e) {
            this.searchInfo.imei = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        deviceModeChange(e) {
            this.searchInfo.deviceMode = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        stateChange(e) {
            this.searchInfo.state = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        enterpriseIdChange(e) {
            this.searchInfo.enterpriseId = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        tapStatusChange(e) {
            this.searchInfo.tapStatus = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        electricityChange(e) {
            this.searchInfo.electricity = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        batchNoChange(e) {
            this.searchInfo.batchNo = e
            this.$emit('update:searchInfo', this.searchInfo)
        }
    }
}
</script>

<style>
.search_container {
    padding: 40px 0 18px;
    /* border-bottom: 1px solid #eff1f4; */
}
</style>
