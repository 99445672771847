<template>
<div id="registDevice" class="main home">
    <div class="home-box">
        <h1>注册设备</h1>
        <div class="home-content">
            <el-form ref="form" :model="form" label-width="90px">
                <el-form-item label="IMEI" required>
                    <el-input class="inputItem" placeholder="请输入IMEI" v-model="form.imei"></el-input>
                    <el-tooltip class="item" content="IMEI:移动设备国际识别码，用于设备鉴权。填写内容需保持与设备内置信息一致。" placement="top-start"><img class="what" :src="require('@/assets/icon/other/what.png')" /></el-tooltip>
                </el-form-item>
                 <el-form-item label="IMSI" required>
                    <el-input class="inputItem" placeholder="请输入IMSI" v-model="form.imsi"></el-input>
                    <el-tooltip class="item" content="国际移动用户识别码，用于区别移动用户的有效信息。填写需保持与SIM卡中信息一致。" placement="top-start"><img class="what" :src="require('@/assets/icon/other/what.png')" /></el-tooltip>
                </el-form-item>
                <el-form-item label="自定义编号">
                    <el-input class="inputItem" placeholder="请输入自定义编号" v-model="form.devNo"></el-input>
                    <el-tooltip class="item" content="XM蓝牙编号,选择其他供应商时可不填" placement="top-start"><img class="what" :src="require('@/assets/icon/other/what.png')" /></el-tooltip>
                </el-form-item>
                <el-form-item label="运营商" required>
                    <el-select v-model="operatorValue" placeholder="请选择">
                        <el-option v-for="item in operatorList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备类型" required>
                    <el-select v-model="form.type" placeholder="请选择">
                        <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.equipmentName" :value="item.equipmentCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属批次" required>
                    <el-select v-model="form.batchNo" placeholder="请选择">
                        <el-option v-for="item in batchList" :key="item.id" :label="item.batchNo" :value="item.batchNo">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供应商" required>
                    <el-radio v-model="form.moduleFactory" :label="1">XM</el-radio>
                    <el-radio v-model="form.moduleFactory" :label="4">JWX</el-radio>
                    <el-radio v-model="form.moduleFactory" :label="2" disabled="disabled">HAC</el-radio>
                    <el-radio v-model="form.moduleFactory" :label="3" disabled="disabled">DX</el-radio>
                </el-form-item>
                <el-form-item label="设备描述">
                    <el-input type="textarea" placeholder="请输入设备描述" class="inputItem" v-model="form.deviceDesc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="submitLoading" @click="onSubmit">注册</el-button>
                    <el-button @click="back">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                imei: '',
                imsi: '',
                devNo: '',
                deviceDesc: '',
                batchNo: '',
                moduleFactory: 1,
                type: ''

            },
            arrEnpr: [],
            dialogVisible: false,
            editState: false,
            operatorValue: '',
            operatorList: [{
                    value: '0',
                    label: '移动'
                },
                {
                    value: '1',
                    label: '电信'
                }
            ],
            waterTypeList: [{
                    value: '1',
                    label: '居民生活用水'
                },
                {
                    value: '2',
                    label: '工业用水'
                },
                {
                    value: '3',
                    label: '行政事业单位用水'
                },
                {
                    value: '4',
                    label: '经营用水'
                },
                {
                    value: '5',
                    label: '特种行业用水'
                }
            ],
            deviceTypeValue: '',
            deviceTypeList: [],
            submitLoading: false,
            batchList: []
        }
    },
    mounted() {
        this.getAllBatchs()
        this.getAllDevieType()
    },
    beforeDestroy() {

    },
    methods: {
        getAllBatchs() {
            this.$api.getAllBatchs({}).then(res => {
                if (res.code === 0) {
                    this.batchList = res.data
                }
            })
        },
        getAllDevieType() {
            this.$api.getAllDeviceType({}).then(res => {
                if (res.code === 0) {
                    this.deviceTypeList = res.data
                }
            })
        },
        onSubmit() {
            console.log(this.form.seriesIdArr)
            if (this.form.imei.toString().trim() == "") {
                this.$message.warning('请输入设备IMEI号码')
                return
            }
            if (this.form.imsi.toString().trim() == "") {
                this.$message.warning('请输入设备IMSI号码')
                return
            }
            if (this.operatorValue == "") {
                this.$message.warning('请选择运营商')
                return
            }
            if (this.form.type.toString().trim() == "") {
                this.$message.warning('请选择设备类型')
                return
            }
            if (this.form.batchNo.toString().trim() == "") {
                this.$message.warning('请选择所属批次')
                return
            }
            let that = this
            let params = {
                imei: this.form.imei,
                imsi: this.form.imsi,
                devNo: this.form.devNo,
                type: this.form.type,
                moduleFactory: this.form.moduleFactory,
                batchNo: this.form.batchNo,
                deviceType: this.operatorValue === '0' ? '移动' : '电信',
                deviceDesc: this.form.deviceDesc
            }
            console.log("@@@@@", params)
            this.submitLoading = true
            this.$api.registration(params).then(res => {
                    this.submitLoading = false
                    if (res.code === 0) {
                        this.$message.success('注册成功')
                        setTimeout(() => {
                            that.$router.push({
                                path: '/'
                            })
                        }, 2000);
                    } else {
                        this.$message.error('注册失败')
                    }
                })
        },
        back() {
            history.back()
        }
    }
}
</script>

<style lang="scss">
#registDevice {
    .inputItem {
        width: 340px;
    }

    .el-input__inner {
        width: 340px;
    }

    .home-content {
        padding-top: 20px;
        padding-bottom: 20px;

        .what {
            width: 14px;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .el-form-item__content {

        align-items: center;
    }
}
</style>
